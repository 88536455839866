import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../layout';
import Seo from '../components/seo';
import BlockMapping from '../blocks/block-mapping';

export const PageTemplate = (props) => {
  const { blocks = [] } = props;
  return (
    <section>
      <BlockMapping blocks={blocks} />
    </section>
  );
};


const Page = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  return (
    <Layout pagePosition={frontmatter?.pagePosition} meta={frontmatter?.meta}>
      <Seo
        {...frontmatter?.meta}
        title={frontmatter.title}
        slug={frontmatter.slug}
        faqs={frontmatter?.faqs}
      />
      <PageTemplate
        {...frontmatter}
      />
    </Layout>
  );
};

export default Page;

export const pageQuery = graphql`
  query PageTemplate($id: String) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        slug
        title
        pagePosition
        faqs {
          question
          answer
        }
        ...MetaTags
        ...BlocksMapping
      }
    }
  }
`;
